import React from "react";

const CourseEligibility = ({eligibility_criteria}) => {
    

  return (
    <div className="content  pb-30 pl-30 pr-30 white-bg">
      { eligibility_criteria ? <h3 className="instructor-title">Eligibility</h3> : null }
      <div className="row rs-team style1 transparent-bg clearfix">
        {eligibility_criteria ? <p
          dangerouslySetInnerHTML={{ __html: eligibility_criteria }}
        /> : null }
      </div>
    </div>
  );
};


export default CourseEligibility;