export const SET_LOGIN_USER_TYPE = 'STORE_DATA';
export const SET_USER_TYPE = "SET_USER";

export const setLoginUser = (user) => {
    return {
        type: SET_LOGIN_USER_TYPE,
        payload: user
    }
}

export const setLogoutUser = () => {
    return {
        type: SET_LOGIN_USER_TYPE,
        payload: null
    }
}

export const setUser = (user) => {
    return {
        type: SET_USER_TYPE,
        payload: {...user}
    }
}