import React from "react";

const UniversityAdmission = ({ eligibility, application_deadlines }) => {
  return (
    <div className="content  pb-30 pl-30 pr-30 white-bg">
      {eligibility ? <h3 className="instructor-title">Eligibility</h3> : null }
      <div className="row rs-team style1 transparent-bg clearfix">
       {eligibility ? <p
          dangerouslySetInnerHTML={{ __html: eligibility }}
        /> : null }
      </div>
     {application_deadlines ?  <h3 className="instructor-title">Application Deadlines</h3> : null }
      <div className="row rs-team style1 transparent-bg clearfix">
      {application_deadlines ?   <p
          dangerouslySetInnerHTML={{ __html: application_deadlines }}
        /> : null }
      </div>
    </div>
  );
};

export default UniversityAdmission;
