import React from "react";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";

const UniversityBreadcrumb = (props) => {
  const {
    breadcrumbsClass,
    innerClass,
    titleClass,
    universityName,
    breadcrumbsImg,
    universityLogo,
    universityState,
    universityCountry,
    universityType,
    isCourse,
    courseName
  } = props;

  return (
    <div className="container">
      <div className={breadcrumbsClass? breadcrumbsClass: "rs-breadcrumbs breadcrumbs-overlay "} style={{"top": "30px","margin-bottom": "61px","overflow": "hidden"}}>
        <div className="breadcrumbs-img university_image">
          <img src={breadcrumbsImg} alt="Breadcrumbs Image" className="uni-banner-img"/>
        </div>
        <div className={innerClass ? innerClass : "breadcrumbs-text white-color"} style={{"border-bottom-left-radius":"20px"}}>
          <div className="university-logo">
            <img
              src={universityLogo}
              alt="University Logo"
              height={100}
              width={100}
            />
          </div>

          <div className="university-content">
            <h4 className={`${titleClass ? titleClass : "page-title"} ${isCourse && 'course-title-breadcrumb'}`} >
              {isCourse &&  <div dangerouslySetInnerHTML={{ __html: courseName }}></div>}
              {(!isCourse && universityName) && universityName}
            </h4>
            {isCourse && <h5 className='sub-title-bread text-white'>{universityName}</h5>}
            <ul>
              <li>
                <i>
                  <FontAwesome
                    className="super-crazy-colors"
                    name="map-marker"
                  />
                </i>{" "}
                {universityState ? universityState : "State"} ,{" "}
                {universityCountry ? universityCountry : "IN"}
              </li>
              <li> {universityType ? universityType + " University" : ""} </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityBreadcrumb;
