import React from "react";

const CourseRanking = ({ranking}) => {
    

  return (
    <div className="content  pb-30 pl-30 pr-30 white-bg">
      {ranking ? <h3 className="instructor-title">Ranking</h3> : null }
      <div className="row rs-team style1 transparent-bg clearfix">
        {ranking ? <p
          dangerouslySetInnerHTML={{ __html: ranking }}
        /> : null }
      </div>
    </div>
  );
};


export default CourseRanking;