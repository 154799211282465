import React, { createContext, useEffect, useMemo, useState } from "react";
import Header from "../../../components/Layout/Header/Header";
import Footer from "../../../components/Layout/Footer/Footer";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import Newsletter from "../../../components/Common/Newsletter";

// Image
import Logo from "../../../assets/img/logo/vision-international.png";
import footerLogo from "../../../assets/img/logo/vision-international.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CourseDetailsMain from "./CourseDetailsMain";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { GlobalGraphqlcall } from "../../../utils/ApiUtil";
import { getCourse, universityQuery } from "../../../graphql/query";
import bannerbg from '../../../assets/img/breadcrumbs/course-banner-blue.jpg';
import { Helmet } from "react-helmet";
import CourseDetailBreadcrumb from "../../../components/Courses/CourseDetailBreadcrumb";
import { RelatedUniversityContext, UniversityContext } from "../../university/university-detail";
import UniversityBreadcrumb from "../../../components/University/UniversityBreadcrumb";

export const CourseContext = createContext();

const CourseDetailPage = () => {
  const location = useLocation();
  const pathParam = useParams();
  const [course, setCourse] = useState({});

  const university = useMemo(() => {
    return course?.university
  }, [course]);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async (id = null) => {
    console.log(pathParam);
    let course_id = location.state?.course_id;
    if (id) {
      course_id = id;
    }
    let variables = {
      id: course_id,
    };
    if(!course_id){
      variables = {university_slug:pathParam.slug, course_slug:pathParam.course}
    }else{

    }
    
    try {
      var res = await GlobalGraphqlcall(getCourse, variables);
      setCourse(res?.data?.getCourse);
    } catch (error) {
      setCourse({});
    }
  };

  return (
    <React.Fragment>
      <OffWrap />
      <UniversityContext.Provider value={course?.university}>
      <CourseContext.Provider value={course}>
        <Header
          parentMenu="course"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="support@website.com"
          Location="374 William S Canning Blvd, MA 2721, USA "
        />
        <Helmet>
          <meta name="title" content={course?.metadata_title} />
          <meta name="description" content={course?.metadata_description} />
          <title>{course?.metadata_title}</title>
          <meta name="keywords" content={course?.metadata_keywords} />
        </Helmet>
        {/* breadcrumb-area-start */}
        {/* <CourseDetailBreadcrumb
                pageTitle="Course Details"
                pageName="Course Details"
                breadcrumbsImg={bannerbg}
            /> */}
          <UniversityBreadcrumb
            isCourse
            innerClass="white-color university-breadcrumbs-text"
            pageTitle="Learn User Interface and User Experience"
            pageName="Course Details"
            breadcrumbsImg={university?.university_banner}
            universityLogo={university?.university_logo}
            courseName={course?.course_name}
            universityName={university?.university_name}
            universityState={university?.state}
            universityCountry={university?.country}
            universityType={university?.university_type}
          />
        {/* breadcrumb-area-start */}

        {/* Course Details Main */}
        <CourseDetailsMain />
        {/* Course Details Main */}

        <Newsletter
          sectionClass="rs-newsletter style1 gray-bg yellow-color mb--90 sm-mb-0 sm-pb-70"
          titleClass="title mb-0 white-color"
        />

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />
        <SearchModal />
      </CourseContext.Provider>
    </UniversityContext.Provider>
    </React.Fragment>
  );
};

export default CourseDetailPage;
