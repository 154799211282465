import { combineReducers } from "redux";
import authReducer from "./authReducer";
import appReducer from "./appReducer";
import filterReducer from "./filterReducer";
import notificationReducer from "./notificationReducer";
import deviceReducer from "./deviceReducer";
import metaReducer from "./metaReducer";
import sourceReducer from "./sourceReducer";

const mainReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    filter: filterReducer,
    noti: notificationReducer,
    device: deviceReducer,
    metaData: metaReducer,
    utm:sourceReducer
});

export default mainReducer;
