import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const CourseItem = ({ item }) => {
  const history = useHistory();

    const searchModalRemove = () => {
        document.body.classList.remove("modal-open");
        const location = {
          pathname: `/course/${item?.university?.university_slug}/${item?.course_slug}`,
          state: {
              course_id: item?.id,
          },
        };

        history.push(location);
    };
  return (
    <React.Fragment>
        <div className="search-item course" role="button"  onClick={searchModalRemove}>
          <div className="content">
            {/* <h4 style={{ color: "white" }}>{item.course_name}</h4> */}
            <p style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: item?.course_name }} />
          </div>
        </div>
    </React.Fragment>
  );
};

export default CourseItem;
