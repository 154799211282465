import { API_HOST } from "../app/App";

export const GlobalAPIcall = async (method, url, data = {}, headers = {}) => {
    let methodCustom = method.toUpperCase();
    let customUrl = url;
    if (method.toUpperCase() === "PATCH" || method.toUpperCase() === "PUT") {
        methodCustom = "POST";
        if (url.includes("?")) {
            customUrl = url + "&_method=" + method.toUpperCase();
        } else {
            customUrl = url + "?_method=" + method.toUpperCase();
        }
    }
    var requestOptions = {
        method: methodCustom,
        headers: {
            ...headers,
        }
    };
    if (method.toUpperCase() !== "GET" && method.toUpperCase() !== "HEAD") {
        requestOptions.body = data;
    }
    var res = await fetch(API_HOST + '/api' + customUrl, requestOptions);
    var json = await res.json();
    return json;
}

/**
 * Call graphql
 * @param {string} query 
 * @param {object} variables 
 * @param {object} headers 
 * @param {any} signal
 * @returns 
 */
export const GlobalGraphqlcall = async (query, variables = {}, headers = {}, signal = null) => {
    const body = JSON.stringify({
        query: query,
        variables: {
            ...variables
        }
    });
    var requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...headers
        },
        body: body,
    };
    if (signal) {
        requestOptions.signal = signal
    }
    var res = await fetch(API_HOST + '/graphql', requestOptions);
    if (signal && signal?.aborted) {
        return null;
    }
    var json = await res.json();
    return json;
}