import { SET_LOGIN_USER_TYPE, SET_USER_TYPE } from "../action/authAction";

const initailState = {};
const authReducer = (state = initailState, action) =>  {
    switch (action.type) {
        case SET_LOGIN_USER_TYPE:
            return {
                ...state,
                user: action.payload
            };
        
        case SET_USER_TYPE: 
            return {
                ...state,
                user: {
                    ...state.user,
                    user: action.payload
                }
            }
    
        default:
            return state;
    }
}
export default authReducer;