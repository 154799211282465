import React, { useEffect } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

const Testimonial = () => {

    useEffect(()=>{
        const script = document.createElement("script");

        script.src = "https://cdn.trustindex.io/loader.js?dca68e3206713291e526b27e401";
        script.async = true;
        const testimonialDiv = document.getElementById("testimonials-45");
        testimonialDiv.appendChild(script);
    }, []);

    const testimonialSettings = {
        dots: true,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-testimonial main-home pt-100 pb-100 md-pt-70 md-pb-70">
                <div className="container"> 
                <SectionTitle
                    sectionClass="sec-title3 text-center mb-44"
                    subtitleClass="sub-title"
                    // subtitle="Select Courses"
                    titleClass="title white-color"
                    title="Testimonials"
                />
                    <div id="testimonials-45"></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial