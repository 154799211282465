import React from "react";
import { Link } from "react-router-dom";

const UniversityCard = (props) => {
  const {
    universityClass,
    universityLogo,
    universityName,
    universityCountryName,
    catLink,
    universityType,
    universityStateName,
    university_slug,
    courseCount
  } = props;

  const location = {
    pathname: `/university/${university_slug}`,
    state: {
      is_view: "course",
    },
  };
  var courseOfferText;
  if(courseCount) {
    if(courseCount > 1) {
      courseOfferText = `${courseCount} Courses`;
    }else{
      courseOfferText = `${courseCount} Course`;
    }
  }else{
    courseOfferText = null;
  }

  return (
    <div className={universityClass ? universityClass : "universities-item"}>
      <div className="img-part uni-logo">
        <img
          src={universityLogo}
          alt={universityName}
          height={100}
          width={100}
        />
      </div>
      <div className="content-part">
        <div className="sub-content">
          <h3 className="title">
            <Link to={"/university/" + university_slug}>
              {universityName
                ? universityName
                : "Become a PHP Master and Make Money Fast"}
            </Link>
          </h3>
        </div>
        <div className="row" >
          <div className="col-md-4">
            <span>Location</span>
            <br />
            <p className="categorie">{universityStateName ? universityStateName : "Gujarat"},{" "}
            {universityCountryName ? universityCountryName : "India"}</p>
          </div>
          <div className="col-md-4">
            <span>University Type</span>
            <br />
           <p> <Link
              className="categorie"
            >
              {universityType ? universityType : " - "}
            </Link></p>
          </div>
          <div className="col-md-4">
            <span>Courses Offered</span>
            <br />
            {courseOfferText ? 
            <Link className="course-text " to={location}>
              {courseOfferText}
            </Link>
            : ' - '}
          </div>
        </div>
        <div className="bottom-part">
          {/* <div className="info-meta">
            <Link to={location}>
              {props.courseLinkText}
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div> */}
          <div className="btn-part">
            <Link to={"/university/" + university_slug}>
              {props.btnText}
              {/* <i className="flaticon-right-arrow"></i> */}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityCard;
