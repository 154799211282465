import ApplyNowModal from "../ApplyNowModal/ApplyNowModal";
import { useState } from "react";

const FeatureStyle1 = ({config}) => {

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const searchModalAdd = () => {
		document.body.classList.add('modal-open');
	};
  return (
    <div className="rs-features main-home">
      <div className="container">
        <div className="content-part">
          <h1 className="text-white heading">{config?.banners_heading} </h1>
          <h3 className="sub-heading"
            dangerouslySetInnerHTML={{ __html: config?.banners_sub_heading }}
          />
          <div className="search-button">
            <input
              type="search"
              placeholder="Search..."
              name="s"
              className="search-input"
              value=""
              onFocus={searchModalAdd}
            />
          </div>
          <div className="sl-btn">
            <a
              className="readon blue-btn main-home"
              href="#"
              onClick={onOpenModal}
            >
              {config?.banners_cta_text}
            </a>
          </div>
          <ApplyNowModal onCloseModal={onCloseModal} open={open} />
        </div>
      </div>
    </div>
  );
};

export default FeatureStyle1;
