import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Course courseImg
import { GlobalGraphqlcall } from "../../utils/ApiUtil";
import { AllCoursesQuery } from "../../graphql/query";
import CourseCard from "../../components/Courses/CourseCard";
import CourseSidebar from "./CourseSidebarSection";

const AllCourseList = () => {
  const perPage = 10;
 
  const [courses, setCourse] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const paginationButton = useMemo(() => {
    if (totalPage > 1) {
      let array = [];
      let first = activePage == 1 ? 1 : activePage - 1;
      if (activePage == totalPage && totalPage > 2) {
        first = activePage - 2;
      }
      if (activePage <= 1) {
        array.push(1);
      } else {
        array.push(first);
      }
      if (totalPage > 1) {
        if (totalPage <= 2) {
          array.push(totalPage);
        } else {
          array.push(first + 1);
        }
      }
      if (totalPage > 2) {
        if (totalPage <= 3 || activePage == totalPage) {
          array.push(totalPage);
        } else {
          array.push(first + 2);
        }
      }

      return [...array];
    } else {
      return [];
    }
  }, [activePage, totalPage]);

  const fetchApi = async (slug = null, filter = {}, page = {}) => {
    var variables = { ...filter };
    if (slug) {
      variables.slug = slug;
    }
    variables.page = page?.page ? page?.page : 1;
    try {
      setLoader(true);
      let data = await GlobalGraphqlcall(AllCoursesQuery, variables);
      if (data?.data?.getCourseList?.data) {
        setCourse([...data?.data?.getCourseList?.data]);
        setTotalPage(
          Math.ceil(data?.data?.getCourseList?.total / perPage)
        );
        setTotalCount(data?.data?.getCourseList?.total);
        if(!page?.page) {
          setActivePage(1);
        }
      }
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const listClassAdd = () => {
    document.getElementById("rs-popular-course").classList.add("list-view");
  };

  const listClassRemove = () => {
    document.getElementById("rs-popular-course").classList.remove("list-view");
  };


  return (
    <div
      id="rs-popular-course"
      className="rs-popular-courses list-view style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <CourseSidebar fetchCourse={fetchApi} page={activePage} />
          </div>
          <div className="col-lg-8 pr-50 md-pr-14">
            <div className="course-search-part">
              <div className="course-view-part">
                <div className="view-icons">
                  <button onClick={listClassRemove} className="view-grid mr-10">
                    <i className="fa fa-th-large"></i>
                  </button>
                  <button onClick={listClassAdd} className="view-list">
                    <i className="fa fa-list-ul"></i>
                  </button>
                </div>
                <div className="view-text">
                  {totalPage > 1 ? (
                    <>
                      Showing {(activePage * perPage) - perPage + 1}-{((activePage * perPage) > totalCount) ? totalCount : activePage * perPage} of{" "}
                      {totalCount} results{" "}
                    </>
                  ) : (
                    <>{totalCount} results</>
                  )}
                </div>
              </div>
            </div>
            <div className="course-part clearfix">
              {courses.map((course, index) => (
                <CourseCard
                  key={index}
                  courseClass="courses-item"
                  course={course}
                  university={course?.university}
                  btnText={"Know More  "}
                />
              ))}
            </div>
            {totalPage > 1 ? (
              <div className="pagination-area blue-color text-center mt-30 md-mt-0">
                <ul className="pagination-part">
                  {activePage != 1 && (
                    <li
                      role="button"
                      onClick={() => setActivePage((page) => page - 1)}
                    >
                      <Link to="#">
                        <i className="fa fa-long-arrow-left"></i>&nbsp;Previous
                      </Link>
                    </li>
                  )}
                  {paginationButton?.map((item) => (
                    <li
                      className={item == activePage && "active"}
                      role="button"
                      onClick={() => setActivePage(item)}
                    >
                      <Link to="#">{item}</Link>
                    </li>
                  ))}
                  {activePage != totalPage && (
                    <li
                      role="button"
                      onClick={() => setActivePage((page) => page + 1)}
                    >
                      <Link to="#">
                        Next <i className="fa fa-long-arrow-right"></i>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCourseList;
