import { SET_MOBILE_DEVICE } from "../action/deviceAction";

const initailState = {
    isMobile: false
};
const deviceReducer = (state = initailState, action) =>  {
    switch (action.type) {
        case SET_MOBILE_DEVICE:
            return {
                ...state,
                isMobile: action.payload
            };
    
        default:
            return state;
    }
}

export default deviceReducer;