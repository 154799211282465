import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/Layout/Header/Header';
import Footer from '../components/Layout/Footer/Footer';
import Newsletter from '../components/Common/Newsletter';
import ScrollToTop from '../components/Common/ScrollTop';
import OffWrap from '../components/Layout/Header/OffWrap';
import SearchModal from '../components/Layout/Header/SearchModal';

// Image
import favIcon from '../assets/img/fav-orange.png';
import Logo from '../assets/img/logo/dark-logo.png';
import footerLogo from '../assets/img/logo/vision-international.png';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { LoginWithGoogle } from '../graphql/query';
import { setLoginUser } from '../redux/action/authAction';
import jwtDecode from 'jwt-decode';
import { GlobalGraphqlcall } from '../utils/ApiUtil';
import UpdateProfile from '../components/UpdateProfile/UpdateProfile';
import { setError, setSuccess } from '../redux/action/notificationAction';

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => (state.auth?.user));

    const responseMessage = (response) => {
        var loginResponse = jwtDecode(response.credential);
        let variables = {
            uuid: loginResponse.aud,
            email: loginResponse.email,
            name: loginResponse.name,
        }
        GlobalGraphqlcall(LoginWithGoogle, variables).then((res) => {
            if (res?.data?.registerLogin) {
                dispatch(setLoginUser(res?.data?.registerLogin));
                history.push('/');
                dispatch(setSuccess("Login successfully"));
            } else {
                dispatch(setError("Somthing is worng"));
            }
        });
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    return (
        <React.Fragment>
            {/* <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet> */}
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='others'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='support@website.com'
                Location='374 William S Canning Blvd, MA 2721, USA '
            />



            {/* Login Part Start */}
            <div className="rs-login pt-5 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="noticed">
                        <div className="main-part">
                            <div className="method-account">
                                <h2 className="login">{user ? <>Profile</> : <>Login</>}</h2>
                                {!user &&
                                    <div className='google-login'>
                                        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                                    </div>}
                                {user &&
                                    <UpdateProfile />
                                }
                                {/* <form>
                                    <input type="email" name="E-mail" placeholder="E-mail" required />
                                    <input type="text" name="text" placeholder="Password" required />
                                    <button type="submit" className="readon submit-btn">login</button>
                                    <div className="last-password">
                                        <p>Not registered? <Link to="/register">Create an account</Link></p>
                                    </div>
                                </form> */}
                                { }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Login Part End */}

            <Newsletter
                sectionClass="rs-newsletter style1 yellow-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            />

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup blue-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>

    );
}


export default Login;