const ApplyNowCards = ({title, img}) => {
  return (
    <div className="col-md-6 mb-4" >
      <div className="card h-100">
        <div className="card-body">
          <div className="card-image why-content">
            <img src={img}/>
          </div>
          <div className="card-title why-title" >{title}</div>
        </div>
      </div>
    </div>
  );
};

export default ApplyNowCards;
