import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const UniversityItem =({item}) => {
    const history = useHistory();

    const searchModalRemove = () => {
        document.body.classList.remove("modal-open");
        history.push(`/university/${item?.university_slug}`);
    };
    
  return (
    <React.Fragment>
        <div className="search-item university" role="button" onClick={searchModalRemove}>
          <div className="image-part">
            <img src={item.university_logo} alt="Image" height={100} width={100}/>
          </div>
          <div className="content">
            <h4 style={{"color": "white"}}>{item?.university_name}</h4>
          </div>
        </div>
    </React.Fragment>
  );
};

export default UniversityItem;
