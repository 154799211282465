import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';

import bannerbg from '../../assets/img/breadcrumbs/5.jpg';
import ContactBreadcrumb from '../../components/Contact/ContactBreadcrumb';


const ContactMain = () => {
    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            <ContactBreadcrumb
                pageTitle="Contact"
                pageName="Contact"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">

                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 md-mb-30">
                            <div className="map-canvas">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1372.5940634626818!2d72.84529838694193!3d19.03024554476056!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf85425dd0f7%3A0xe7071360b840267a!2sVision%20International!5e0!3m2!1sen!2sin!4v1691778903791!5m2!1sen!2sin" ></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-widget">
                                <SectionTitle
                                    sectionClass="sec-title3 mb-36"
                                    titleClass="title black-color mb-14"
                                    title="Get in Touch"
                                    descClass="new-desc"
                                    description="Have some suggestions or just want to say hi? Our  support team are ready to help you 24/7."
                                />
                                {/* Contact Form */}
                                <ContactForm
                                    submitBtnClass="btn-send"
                                    btnText="Submit"
                                />
                                {/* Contact Form */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default ContactMain;