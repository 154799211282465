import React from "react";
import { Link } from "react-router-dom";

const UniversitySingle = (props) => {
  const {
    university_slug,
    universityBanner,
    title,
    universityState,
    quantity,
    itemClass,
    universityLogo,
  } = props;
  return (
    <div className={itemClass}>
      <div className="universities-grid">
        <div className="img-part">
          <Link to={"/university/" + university_slug}>
            <img
              className="banner"
              style={{ height: "160px", width: "100%" }}
              src={universityBanner}
              alt={title}
            />
          </Link>
        </div>
        <div className="content-part">
          <div>
            <img
              className="home-uni-logo"
              style={{
                height: "60px",
                width: "60px",
              }}
              src={universityLogo}
              alt={title}
            />
          </div>
          <h3 className="title">
            <Link to={"/university/" + university_slug}>{title}</Link>
          </h3>
          <ul className="meta-part">
            <li className="user">
              <i className="fa fa-building"></i>
              {universityState}
            </li>
            <li className="user">
              <div className="btn-part">
                <Link to={"/university/" + university_slug}>
                  View Details
                  {/* <i className="flaticon-right-arrow"></i> */}
                </Link>
              </div>
            </li>
          </ul>
          <span className="university-qnty">{quantity}</span>
        </div>
      </div>
    </div>
  );
};

export default UniversitySingle;
