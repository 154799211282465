import { SET_COURSE_FILTER_TYPE, SET_UNIVERSITY_FILTER_TYPE } from "../action/filterAction";


const initailState = {
    university: [],
    course: []
};
const filterReducer = (state = initailState, action) =>  {
    switch (action.type) {
        case SET_UNIVERSITY_FILTER_TYPE:
            return {
                ...state,
                university: [...action.payload]
            };

        case SET_COURSE_FILTER_TYPE:
            return {
                ...state,
                course: [...action.payload]
            };
    
        default:
            return state;
    }
}
export default filterReducer;