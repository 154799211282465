import React from "react";
import CourseCard from "../Courses/CourseCard";

const CourseList = ({ courses, university }) => {
  return (
    <div id="rs-popular-course" className="rs-popular-courses list-view style1 course-view-style orange-style rs-inner-blog white-bg pb-100 md-pt-70 md-pb-80 ">
      <div className="container">
        <div className="row">
          <div className="course-part clearfix">
            {courses?.map((course, index) => (
              <CourseCard
                key={index}
                courseClass="courses-item"
                course={course}
                university={university}
                btnText={'Know More  '}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseList;
