import React from "react";

const CourseFees = ({fees_expenses, course_price}) => {
    

  return (
    <div className="content pb-30 pl-30 pr-30 white-bg">
      <h3 className="instructor-title">Fees</h3>
      <div className="row rs-team style1 transparent-bg clearfix">
        <p
          dangerouslySetInnerHTML={{ __html: fees_expenses, course_price }}
        />
      </div>
    </div>
  );
};


export default CourseFees;