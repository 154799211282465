import React, { useContext, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import UniversityRanking from './UniversityRanking';
import UniversityGallery from './UniversityGallery';
import UniversityAbout from './UniversityAbout';
import UniversityAdmission from './UniversityAdmission';
import { useEffect } from 'react';
import { UniversityContext } from '.';
import CourseList from '../../../components/CourseList/CourseList';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const UniversityDetailsTab = ({activeIndex, setActiveIndex}) => {
    const university = useContext(UniversityContext);
    const location = useLocation();
    const courseIndex = 1;

    useEffect(() => {
        if (location.state?.is_view == 'course') {
            setActiveIndex(courseIndex);
        }
    }, []);

    return (
        <div className="intro-info-tabs">
            <Tabs selectedIndex={activeIndex}>
                {(university?.about_university || university?.university_campus) ?
                    <TabPanel>
                        <UniversityAbout about={university?.about_university} campus={university?.university_campus} />
                    </TabPanel>
                : null }

                {(university?.courses.length > 0) ?
                    <TabPanel>
                        <CourseList courses={university?.courses} university={university} />
                    </TabPanel>
                : null }

            {(university?.admission_eligibility || university?.admission_application_deadlines) ?
                <TabPanel>
                    <UniversityAdmission eligibility={university?.admission_eligibility} application_deadlines={university?.admission_application_deadlines} />
                </TabPanel>
            : null }

            {(university?.ranking_details) ? 
                <TabPanel>
                    <UniversityRanking ranking={university?.ranking_details} />
                </TabPanel>
            :null}

                {university?.university_images.length > 0 ?
                <TabPanel>
                    <UniversityGallery />
                </TabPanel>
                : null }
            </Tabs>
        </div>
    );
}

export default UniversityDetailsTab;