import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import UniversityPart from './UniversitySection';


const UniversityMain = () => {

    return (
        <React.Fragment>

            {/* CoursePart Start */}
            <UniversityPart />
            {/* CoursePart End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup blue-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default UniversityMain;