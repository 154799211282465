import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UniversityContext } from ".";
import FontAwesome from "react-fontawesome";
import ApplyNowModal from "../../../components/ApplyNowModal/ApplyNowModal";
import { Link } from "react-router-dom";
import { LoginModelContext } from "../../../app/App";

const UniversitySidebar = ({ course }) => {
  const authUser = useSelector((state) => state?.auth?.user);
  const universitites = useContext(UniversityContext);
  const relatedUniversities = universitites?.related_universities || [];
  const config = useSelector((state) => state?.app?.app?.sidebar);
  const { setShow } = useContext(LoginModelContext);
  const brochure_pdf = useMemo(() => {
    return course?.brochure_pdf
  }, [course]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const location = {
    pathname: `/universities/${universitites?.country_slug}`,
    state: {
      filter: {
        country: [`${universitites?.country_slug}`],
      },
    },
  };

  return (
    <div className="inner-column">
      <div className="btn-part">
        <a
          href="#"
          className="btn button readon2 blue apply-text"
          onClick={onOpenModal}
        >
          {config?.sidebar_button_title}
        </a>
        {course &&
          <>
            {authUser ?
              <>
                {brochure_pdf &&
                  <a className="btn button readon2 blue apply-text" href={brochure_pdf?.download_url} target="_blank">
                    Download Brochure
                  </a>
                }
              </>
              :
              <button className="btn button readon2 blue apply-text" onClick={() => setShow(true)}>
                Login to Download Brochure
              </button>
            }
          </>
        }
      </div>

      <ApplyNowModal
        universitites={universitites}
        onCloseModal={onCloseModal}
        open={open}
        coursePart={course}
      />

      <div className="intro-video media-icon orange-color2">
        {config?.sidebar_promotional_banner_image?.source_type == "video" ? (
          <video width="320" height="240" controls autoPlay loop>
            <source
              src={config?.sidebar_promotional_banner_image?.src}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            className="video-img"
            src={config?.sidebar_promotional_banner_image?.src}
          />
        )}
        <h4>{config?.promotional_text}</h4>
      </div>

      <div className="state-weather-image mt-30">
        {universitites?.state_v2?.state_weather_image?.src ? (
          <div className="intro-video media-icon orange-color2">
            <img
              className="video-img"
              src={universitites?.state_v2?.state_weather_image?.src}
            />
          </div>
        ) : null}
      </div>

      <div className="course-features-info">
        <ul>
          <h5>University in {universitites?.country} </h5>
          {relatedUniversities.length > 0 &&
            relatedUniversities.map((university, index) => (
              <div key={index}>
                <li className="lectures-feature d-flex">
                  <div>
                    <img
                      src={university?.university_logo}
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="sidebar-university-name">
                    {/* <span className="label">{university.university_name}</span> */}
                    <Link to={"/university/" + university?.university_slug}>
                      {university?.university_name
                        ? university?.university_name
                        : "Become a PHP Master and Make Money Fast"}
                    </Link>
                    <br />
                    <i>
                      <FontAwesome
                        className="super-crazy-colors label"
                        name="map-marker"
                      />
                    </i>
                    <span className="label" style={{ paddingLeft: "3px" }}>
                      {university?.state}, {university?.country}
                    </span>
                  </div>
                </li>
              </div>
            ))}

          <div className="btn-part view-all">
            <Link to={location} className="view-all-button-sidebar apply-text">
              View All
            </Link>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default UniversitySidebar;
