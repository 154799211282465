import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const FooterBottom = (props) => {
    const config = useSelector((state) => (state?.app?.app));
    const { footerLogo, footerClass, footerTopClass } = props;
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-12 ">
                        <div className="copyright md-mb-0">
                            <p>{config?.copyright_text ? config?.copyright_text : "© 2023 All Rights Reserved By Vision International"}</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;