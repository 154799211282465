import React, { useEffect, useState } from "react";
import { GlobalGraphqlcall } from "../../utils/ApiUtil";
import { contactUsers } from "../../graphql/query";
import { useDispatch, useSelector } from "react-redux";
import { setError as setNotificationError, setSuccess } from "../../redux/action/notificationAction";

const ContactForm = (props) => {
  const { submitBtnClass, btnText } = props;
  const dispatch = useDispatch();
  const source = useSelector((state) => state?.utm?.source);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    let errorObj = {};

    if (name == "") {
      errorObj.name = "Name is Required";
      isError = true;
    } else if (!/^[A-z]*$|^[A-z]+\s[A-z]*$/.test(name)) {
      isError = true;
      errorObj.name = "Name is not valid";
    }

    if (email == "") {
      errorObj.email = "Email is Required";
      isError = true;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      isError = true;
      errorObj.email = "Email is not valid";
    }
    
    if (mobile == "") {
      isError = true;
      errorObj.mobile = "Mobile is Required";
    } else if (!/[0-9]{10}$/.test(mobile) || mobile.length > 10) {
      isError = true;
      errorObj.mobile = "Mobile is not valid";
    }

    if (message == "") {
      isError = true;
      errorObj.message = "Message is Required";
    }
    setError({ ...errorObj });
    try {
      if (!isError) {
        setLoader(true);
        let variables = {
          name,
          email,
          mobile,
          message,
          page_name: "Contact",
          ...source
        };
        let res = await GlobalGraphqlcall(contactUsers, variables);
        if (res?.errors) {
          let category = res?.errors[0].extensions?.category;
          let serverError = res?.errors[0].extensions;
          let server_error = serverError[category];
          let errorObj = {};
          for (const obj in server_error) {
            const filedErrorMessage = server_error[obj][0];
            errorObj[obj] = filedErrorMessage;
          }
          setError({ ...errorObj });
          dispatch(setNotificationError("Somthing is worng"));
        } else {
          setMessage("");
          setEmail("");
          setMobile("");
          setName("");
          dispatch(setSuccess("Thank you for getting in touch!"));
        }
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
      setError({});
      setLoader(false);
    }
  };

  return (
    <form
      id="contact-form"
      method="post"
      action="#"
      onSubmit={handleSubmit}
      noValidate
    >
      <div className="row">
        <div className="col-md-12 mb-30">
          <input
            className="from-control"
            type="text"
            id="name"
            name="name"
            value={name}
            placeholder="Name"
            minlength="2"
            maxlength="50"
            required
            onChange={(e) => setName(e.target.value)}
          />
          {error?.name && <span className="text-danger">{error?.name}</span>}
        </div>

        <div className="col-md-12 mb-30">
          <input
            className="from-control"
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder="E-Mail"
            minlength="2"
            maxlength="50"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          {error?.email && <span className="text-danger">{error?.email}</span>}
        </div>

        <div className="col-md-12 mb-30">
          <input
            className="from-control"
            type="text"
            id="mobile"
            name="mobile"
            value={mobile}
            placeholder="Mobile Number"
            required
            maxlength="10"
            onChange={(e) => setMobile(e.target.value)}
          />
          {error?.mobile && (
            <span className="text-danger">{error?.mobile}</span>
          )}
        </div>

        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="message"
            name="message"
            value={message}
            placeholder="Your message Here"
            required
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          {error?.message && (
            <span className="text-danger">{error?.message}</span>
          )}
        </div>
      </div>
      <div className="btn-part">
        <button
          className={
            submitBtnClass ? submitBtnClass : "readon learn-more submit"
          }
          disabled={loader}
          type="submit"
        >
          {btnText ? btnText : "Submit Now"}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
