import React from 'react';
import { Link } from 'react-router-dom'

const CategoriesSingleTwo = (props) => {
    const { categoriesClass, iconImg, catLink, title, totleCourse, course_id } = props;

    const location = {
        pathname: `/courses/${title.toLowerCase()}`,
        state: {
          filter: {
            course: [`${course_id}`]
          }
        },
      };

    return (
        <Link className={categoriesClass ? categoriesClass : 'categories-item'} to={catLink ? catLink : location}>
            <div className="icon-part">
                <img
                    src={iconImg}
                    alt={title}
                />
            </div>
            <div className="content-part">
                <h4 className="title">{title ? title : 'General Education'}</h4>
                <span className="courses">{totleCourse ? totleCourse + ' Courses' : ' - '} </span>
            </div>
        </Link>
    )
}

export default CategoriesSingleTwo