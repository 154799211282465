import React, { useContext, useMemo, useState } from 'react';
import UniversityDetailsTab from './UniversityDetailsTab';
import UniversitySidebar from './UniversitySidebar';
import { UniversityContext } from '.';

const UniversityDetailsPart = () => {
    const university = useContext(UniversityContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const buttons = useMemo(() => {
        let newButtons = new Array();
        if (university?.about_university || university?.university_campus) {
            newButtons.push("About");
        }
        if (university?.courses.length > 0) {
            newButtons.push("Courses & Fees");
        }
        if (university?.admission_eligibility || university?.admission_application_deadlines) {
            newButtons.push("Admission");
        }
        if (university?.ranking_details) {
            newButtons.push("Ranking");
        }
        if (university?.university_images.length > 0) {
            newButtons.push("Gallery");
        }
        return newButtons;
    }, [university]);
    return (
        <React.Fragment>
            <ul className='d-flex container tab-buttons tab-custom-css'>
                {buttons.map((item, index) => (
                    <li className={`tab-button ${index == activeIndex && 'active'}`} role='button' key={index} onClick={() => setActiveIndex(index)}>{item}</li>
                ))}
            </ul>
            <div className="intro-section pt-94 pb-100 md-pt-80 md-pb-80 loaded">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-8 md-mb-50">
                            <UniversityDetailsTab activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                        </div>
                        <div className="video-column col-lg-4">
                            <UniversitySidebar />
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default UniversityDetailsPart;