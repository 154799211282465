import React from "react";

const CourseApplicationProcess = ({application_process}) => {
    

  return (
    <div className="content pb-30 pl-30 pr-30 white-bg">
      {application_process ? <h3 className="instructor-title">Application Process</h3> : null }
      <div className="row rs-team style1 transparent-bg clearfix">
        {application_process ? <p
          dangerouslySetInnerHTML={{ __html: application_process }}
        /> : null }
      </div>
    </div>
  );
};


export default CourseApplicationProcess;