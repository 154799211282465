export const SET_UNIVERSITY_FILTER_TYPE = 'SET_UNIVERSITY_FILTER_TYPE';
export const SET_COURSE_FILTER_TYPE = 'SET_COURSE_FILTER_TYPE';

export const setUniversityFilter = (filter) => {
    return {
        type: SET_UNIVERSITY_FILTER_TYPE,
        payload: filter
    }
}

export const setCourseFilter = (filter) => {
    return {
        type: SET_COURSE_FILTER_TYPE,
        payload: filter
    }
}
