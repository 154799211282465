import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import AllCourseList from './CourseSection';


const CourseMain = () => {

    return (
        <React.Fragment>

            {/* CoursePart Start */}
            <AllCourseList />
            {/* CoursePart End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup blue-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default CourseMain;