import React, { useEffect, useState } from "react";
import ApplyNowCards from "../ApplyNowCard";
import { InputAdornment, TextField } from "@mui/material";
import {decode} from 'html-entities';
import { Modal } from "react-responsive-modal";
import logo from "../../assets/img/logo/leadform_logo.png";
import EducationLoan from "../../assets/img/custom/Education-loan.png";
import PersonalizedCounselling from "../../assets/img/custom/Personalized-Counselling.png";
import studentHousingSupport from "../../assets/img/custom/student-housing-support.png";
import students from "../../assets/img/custom/students.png";
import universityPartnership from "../../assets/img/custom/university-partnership.png";
import VissaAcceptance from "../../assets/img/custom/Vissa-acceptance.png";
import { leadsquaredApiApplyToUniversity } from "../../graphql/query";
import { GlobalGraphqlcall } from "../../utils/ApiUtil";
import {
  setError as setErrorNotification,
  setSuccess as setSuccessNotification,
} from "../../redux/action/notificationAction";
import { useDispatch, useSelector } from "react-redux";

const ApplyNowModal = ({ universitites, onCloseModal, open, coursePart }) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const source = useSelector((state) => state?.utm?.source);
  const user = useSelector((state) => state?.auth?.user?.user);
  const [error, setError] = useState({});
  const [name, setName] = useState(user?.name ? user?.name : "");
  const [email, setEmail] = useState(user?.email ? user?.email : "");
  const [mobile, setMobile] = useState(user?.mobile ? user?.mobile : "");
  const [location, setLocation] = useState("");
  const [course, setCourse] = useState("");
  useEffect(() => {
    if (coursePart?.course_name) {
      setCourse(decode(coursePart?.course_name));
    }
  }, [coursePart?.course_name]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    let errorObj = {};

    if (name == "") {
      errorObj.name = "Name is Required";
      isError = true;
    } else if (!/^[a-zA-Z\s]*$/.test(name)) {
      isError = true;
      errorObj.name = "Name is not valid";
    }

    if (email == "") {
      errorObj.email = "Email is Required";
      isError = true;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      isError = true;
      errorObj.email = "Email is not valid";
    }

    if (mobile == "") {
      isError = true;
      errorObj.mobile = "Mobile is Required";
    } else if (!/[0-9]{10}$/.test(mobile) || mobile.length > 10) {
      isError = true;
      errorObj.mobile = "Mobile is not valid";
    }

    if (location == "") {
      isError = true;
      errorObj.location = "Location is Required";
    }

    setError({ ...errorObj });
    try {
      if (!isError) {
        setLoader(true);
        var page_name = "NA";
        if (universitites) {
          page_name = universitites?.university_name;
        }
        if (coursePart) {
          page_name = course + " - " + universitites?.university_name;
        }
        if (!coursePart && !universitites) {
          page_name = "Home";
        }
        let variables = {
          name,
          email,
          mobile,
          location,
          course,
          page_name,
          ...source,
        };
        if (universitites) {
          variables.university_id = universitites?.id;
          variables.university_name = universitites?.university_name;
        }
        let res = await GlobalGraphqlcall(
          leadsquaredApiApplyToUniversity,
          variables
        );
        if (res?.errors) {
          let category = res?.errors[0].extensions?.category;
          let serverError = res?.errors[0].extensions;
          let server_error = serverError[category];
          let errorObj = {};
          for (const obj in server_error) {
            const filedErrorMessage = server_error[obj][0];
            errorObj[obj] = filedErrorMessage;
          }
          setError({ ...errorObj });
          dispatch(setErrorNotification("Somthing Went wrong.."));
        } else {
          setEmail("");
          setMobile("");
          setName("");
          setLocation("");
          setCourse("");
          dispatch(setSuccessNotification("Form submitted"));
        }
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
      setError({});
      setLoader(false);
    }
  };

  const sideTabs = [
    {
      title: "Personalized Counselling by Founder & CEO",
      img: PersonalizedCounselling,
    },
    {
      title: "300+ University Partnerships",
      img: universityPartnership,
    },
    {
      title: "6,000+ Students",
      img: students,
    },
    {
      title: "99.6% Visa Acceptance Rate",
      img: VissaAcceptance,
    },
    {
      title: "Education Loan Assistance ",
      img: EducationLoan,
    },
    {
      title: "Student Housing Support",
      img: studentHousingSupport,
    },
  ];
  return (
    <Modal open={open} onClose={onCloseModal} center>
      <div className="row col-md-12">
        <div
          class="col-md-4 why-section"
        >
          <h6 style={{ color: "orange" }}>
            How Vision International helps you in Admission
          </h6>
          <div className="row col-md-12">
            {sideTabs.map((item, index) => (
              <ApplyNowCards title={item.title} key={index} img={item.img} />
            ))}
          </div>
        </div>
        <div class="col-md-8">
          <div
            style={{
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
              paddingBottom: "15px",
              marginTop: "40px",
            }}
          >
            <div className="popup-university-image mr-3 d-flex">
              {universitites?.university_logo ? (
                <img src={universitites?.university_logo} height="50" />
              ) : (
                <img src={logo} height="50" />
              )}
            </div>
            <div style={{ marginBlock: "auto", marginInlineStart: "12px" }}>
              <h4 style={{ color: "orange", marginBottom: "10px" }}>
                Register Now To Apply
              </h4>
              <h5 style={{ marginBottom: "0" }}>
                {universitites?.university_name
                  ? universitites?.university_name
                  : "Get details and latest updates"}
              </h5>
            </div>
          </div>
          <form
            id="contact-form"
            method="post"
            action="#"
            style={{ marginTop: "25px" }}
            onSubmit={handleSubmit}
          >
            <div className="row col-md-12">
              <div class="col-md-6">
                <TextField
                  error={error?.name ? true : false}
                  id="outlined-required"
                  label="Full Name"
                  margin="dense"
                  fullWidth
                  name="name"
                  value={name}
                  inputProps={{ minLength: 2, maxLength: 80 }}
                  onChange={(e) => setName(e.target.value)}
                  helperText={error?.name}
                  startAdornment={<i class="fa fa-paper-plane"></i>}
                />
              </div>
              <div class="col-md-6">
                <TextField
                  error={error?.email ? true : false}
                  id="outlined-required"
                  label=" Email Address"
                  margin="dense"
                  fullWidth
                  name="email"
                  value={email}
                  inputProps={{ maxLength: 80 }}
                  onChange={(e) => setEmail(e.target.value)}
                  helperText={error?.email}
                />
              </div>
              <div class="col-md-6">
                <TextField
                  error={error?.mobile ? true : false}
                  id="outlined-required"
                  label="Mobile Number"
                  margin="dense"
                  fullWidth
                  name="mobile"
                  value={mobile}
                  InputProps={{
                    maxLength: 10,
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  onChange={(e) => setMobile(e.target.value)}
                  helperText={error?.mobile}
                />
              </div>
              <div class="col-md-6">
                <TextField
                  error={error?.location ? true : false}
                  id="outlined-required"
                  label="City You Live In"
                  margin="dense"
                  fullWidth
                  name="location"
                  value={location}
                  inputProps={{ minLength: 2 }}
                  onChange={(e) => setLocation(e.target.value)}
                  helperText={error?.location}
                />
              </div>
              <div class="col-md-6">
                <TextField
                  id="outlined-required"
                  label="Course Interested In"
                  margin="dense"
                  fullWidth
                  name="course"
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="btn-apply btn-primary"
                style={{ color: "white", fontFamily: '"Nunito", sans-serif' }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default ApplyNowModal;
