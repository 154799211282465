import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Course courseImg
import { GlobalGraphqlcall } from "../../utils/ApiUtil";
import { universityQuery } from "../../graphql/query";
import UniversityCard from "../../components/University/UniversityCard";
import UniversitySidebar from "./UniversitySidebarSection";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const UniversityPart = (props) => {
  const perPage = 10;
  let abortRequest;
  const [universities, setUniversities] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const { country : countryInParam } = useParams();
  const paginationButton = useMemo(() => {
    if (totalPage > 1) {
      let array = [];
      let first = activePage == 1 ? 1 : activePage - 1;
      if (activePage == totalPage && totalPage > 2) {
        first = activePage - 2;
      }
      if (activePage <= 1) {
        array.push(1);
      } else {
        array.push(first);
      }
      if (totalPage > 1) {
        if (totalPage <= 2) {
          array.push(totalPage);
        } else {
          array.push(first + 1);
        }
      }
      if (totalPage > 2) {
        if (totalPage <= 3 || activePage == totalPage) {
          array.push(totalPage);
        } else {
          array.push(first + 2);
        }
      }

      return [...array];
    } else {
      return [];
    }
  }, [activePage, totalPage]);

  useEffect(() => {
    fetchApi();
    setFirstLoad(false);

    return () => {
      setFirstLoad(true);
    }
  }, []);

  const fetchApi = async (search = null, filter = {}, page = {}) => {
    if(typeof abortRequest !== 'undefined') {
      abortRequest.abort();
    }
    var variable = { ...filter };
    if(firstLoad) {
      variable.country = [countryInParam];
    }
    if (search) {
      variable.name = search;
    }
    variable.page = page?.page ? page?.page : 1;
    try {
      setLoader(true);
      abortRequest = new AbortController();
      const signal = abortRequest.signal;
      let data = await GlobalGraphqlcall(universityQuery, variable, {}, signal);
      if (data?.data?.getUniversityList?.data) {
        setUniversities([...data?.data?.getUniversityList?.data]);
        var totalPage1 = Math.ceil(
          data?.data?.getUniversityList?.total / perPage
        );
        setTotalCount(data?.data?.getUniversityList?.total);
        setTotalPage(totalPage1);
        if(!page?.page) {
          setActivePage(1);
        }
      }
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const listClassAdd = () => {
    document.getElementById("rs-popular-university").classList.add("list-view");
  };

  const listClassRemove = () => {
    document
      .getElementById("rs-popular-university")
      .classList.remove("list-view");
  };

  return (
    <div
      id="rs-popular-university"
      className="rs-popular-universities list-view style1 university-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <UniversitySidebar fetchUniversity={fetchApi} page={activePage} />
          </div>
          <div className="col-lg-8 pr-50 md-pr-14">
            <div className="university-search-part">
              <div className="university-view-part">
                <div className="view-icons">
                  <button onClick={listClassRemove} className="view-grid mr-10">
                    <i className="fa fa-th-large"></i>
                  </button>
                  <button onClick={listClassAdd} className="view-list">
                    <i className="fa fa-list-ul"></i>
                  </button>
                </div>
                <div className="view-text">
                  {totalPage > 1 ? (
                    <>
                      Showing {(activePage * perPage) - perPage +1}-{((activePage * perPage) > totalCount) ? totalCount : activePage * perPage} of{" "}
                      {totalCount} results{" "}
                    </>
                  ) : (
                    <>{totalCount} results</>
                  )}
                </div>
              </div>
            </div>
            <div className="university-part clearfix">
              {universities.map((university, index) => (
                <UniversityCard
                  key={index}
                  universityClass="universities-item"
                  universityLogo={university.university_logo}
                  universityCountryName={university.country}
                  universityStateName={university.state}
                  universityName={university.university_name}
                  universityType={university.university_type}
                  university_slug={university.university_slug}
                  courseCount={university.course_count}
                  btnText={"Know More  "}
                  courseLinkText={"View Courses "}
                />
              ))}
            </div>
            {totalPage > 1 ? (
              <div className="pagination-area blue-color text-center mt-30 md-mt-0">
                <ul className="pagination-part">
                  {activePage != 1 && (
                    <li
                      role="button"
                      onClick={() => setActivePage((page) => page - 1)}
                    >
                      <Link to="#">
                        <i className="fa fa-long-arrow-left"></i>&nbsp;Previous
                      </Link>
                    </li>
                  )}
                  {paginationButton?.map((item) => (
                    <li
                      className={item == activePage && "active"}
                      role="button"
                      onClick={() => setActivePage(item)}
                    >
                      <Link to="#">{item}</Link>
                    </li>
                  ))}
                  {activePage != totalPage && (
                    <li
                      role="button"
                      onClick={() => setActivePage((page) => page + 1)}
                    >
                      <Link to="#">
                        Next <i className="fa fa-long-arrow-right"></i>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityPart;
