import React, { useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { useEffect } from "react";
import CourseIntro from "./CourseIntro";
import CourseRanking from "./CourseRanking";
import CourseFees from "./CourseFees";
import CourseEligibility from "./CourseEligibility";
import CourseApplicationProcess from "./CourseApplicationProcess";
import { CourseContext } from ".";

const CourseDetailsTab = ({ activeIndex }) => {
  const course = useContext(CourseContext);

  return (
    <div className="intro-info-tabs">
      <Tabs selectedIndex={activeIndex}>
        {course?.course_intro ? (
          <TabPanel>
            <CourseIntro intro={course?.course_intro} />
          </TabPanel>
        ) : null}

        {course?.course_ranking ? (
          <TabPanel>
            <CourseRanking ranking={course?.course_ranking} />
          </TabPanel>
        ) : null}

        {course?.course_fees_expenses ? (
          <TabPanel>
            <CourseFees fees_expenses={course?.course_fees_expenses} />
          </TabPanel>
        ) : null}

        {course?.course_eligibility_criteria ? (
          <TabPanel>
            <CourseEligibility
              eligibility_criteria={course?.course_eligibility_criteria}
            />
          </TabPanel>
        ) : null}

        {course?.course_application_process ? (
          <TabPanel>
            <CourseApplicationProcess
              application_process={course?.course_application_process}
            />
          </TabPanel>
        ) : null}
      </Tabs>
    </div>
  );
};

export default CourseDetailsTab;
