import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { GlobalAPIcall, GlobalGraphqlcall } from "../../utils/ApiUtil";
import { getUser } from "../../graphql/query";
import { setUser } from "../../redux/action/authAction";
import { setError, setSuccess } from "../../redux/action/notificationAction";

const UpdateProfile = ({ isModel, closeModel }) => {
    const user = useSelector((state) => (state.auth?.user));
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const formRef = useRef(null);

    useEffect(() => {
        if (user?.user) {
            const { user: { mobile, name } } = user;
            setName(name);
            setPhoneno(mobile);
        }
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = formRef.current;
        const formData = new FormData(form);
        const res = await GlobalAPIcall('POST', '/user/profile/update', formData, {
            "Authorization": user.token
        });
        if(res.status) {
            dispatch(setSuccess(res.message));
            const userData = await GlobalGraphqlcall(getUser,{token: user.token});
            if(userData?.data?.getUser) {
                dispatch(setUser(userData?.data?.getUser));
            }
        }else {
            dispatch(setError(res.message));
        }
        if(isModel) {
            closeModel();
        }
        return true;
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit}>
            <img src={user.user?.image} width={60} height={60} style={{ borderRadius: "50%", height:"60px", width:"60px" }} className="mb-10"/>
            <input type="text" name="name" placeholder="Phone No" required onChange={(e) => setName(e.target.value)} value={name} />
            <input type="text" name="mobile" placeholder="Mobile" required onChange={(e) => setPhoneno(e.target.value)} value={phoneno} />
            <input type="file" name="image" placeholder="Profile Image" className="prifile-image-input" />
            <button type="submit" onClick={handleSubmit} className="readon submit-btn">Update Profile</button>
        </form>
    )
}

export default UpdateProfile;