import React, { useEffect } from "react";
import OffWrap from "../../components/Layout/Header/OffWrap";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import HomeMain from "./HomeMain";

import footerLogo from "../../assets/img/logo/vision-international.png";
import { useDispatch, useSelector } from "react-redux";
import { setLoginUser } from "../../redux/action/authAction";
import { Helmet } from "react-helmet";

const HomePage = () => {
  // const dispatch = useDispatch();
  const config = useSelector((state) => state?.app?.app?.home_page_metadata);
  // const { metadata_title, metadata_description, metadata_keywords } = config;

  return (
    <React.Fragment>
      <OffWrap />
      {/* {user?.name}
            {user?.email} */}
      <Header parentMenu="home"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="support@website.com"
          Location="374 William S Canning Blvd, MA 2721, USA "
           />
      <Helmet>
        <meta name="title" content={config?.metadata_title} />
        <meta name="description" content={config?.metadata_description} />
        <title>{config?.metadata_title}</title>
        <meta name="keywords" content={config?.metadata_keywords} />
      </Helmet>
      <HomeMain />
      <Newsletter
        sectionClass="rs-newsletter style1 yellow-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default HomePage;
