import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItems = (props) => {
  const { parentMenu, secondParentMenu } = props;

  const location = useLocation();

  return (
    <React.Fragment>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/universities">Universities</Link>
      </li>
      <li>
        <Link to="/courses">Courses</Link>
      </li>

      <li>
        <Link to="/contact">Get Counselling</Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
