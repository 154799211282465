import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import CategoriesSingleTwo from '../../components/Categories/CategoriesSingleTwo';


// Categories Icon
import icon1 from '../../assets/img/categories/icons/12.png';
import { useSelector } from 'react-redux';

const Categories = (props) => {
    const specializations = useSelector((state) => (state?.app?.app?.home_page_specialization));
   
    return (
        <div className="rs-categories gray-bg style1 md-pb-40 top-course">
            <div className="container">
                <div className="row y-middle mb-50 md-mb-30">
                    <div className="col-md-6 col-sm-4 sm-text-left course-section">
                        <SectionTitle
                            sectionClass="sec-title"
                            subtitleClass="sub-title primary"
                            // subtitle="Subject Categories"
                            titleClass="title mb-0"
                            title="Our Top Courses"
                        />
                    </div>
                    <div className="col-md-6 col-sm-4 all-course-link">
                        <div className="btn-part text-right sm-text-right">
                            <Link to={`/courses`} className="readon">View All Courses</Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {specializations?.map((specialization, index) => (
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CategoriesSingleTwo
                                categoriesClass="categories-item"
                                title={specialization.specialization_name}
                                totleCourse={specialization.course_count}
                                iconImg={specialization.specialization_icon}
                                course_id={specialization.id}
                                />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Categories;