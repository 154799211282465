import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

// Courses Image
import UniversitySingle from '../../components/University/UniversitySingle';
import { useSelector } from 'react-redux';

const Universities = () => {
    const universities = useSelector((state) => (state?.app?.app?.home_page_university));
    return (
        <div className="rs-popular-universities main-home event-bg pt-40 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title3 text-center mb-44"
                    subtitleClass="sub-title"
                    // subtitle="Select Courses"
                    titleClass="title black-color"
                    title="Universities"
                />
                <div className="row">
                    {universities?.map((university, index) => (
                        <div className="col-lg-4 col-md-6 mb-30">
                            <UniversitySingle
                                itemClass="universities-item"
                                universityBanner={university.university_banner}
                                title={university.university_name}
                                universityState={university.state}
                                university_slug={university.university_slug}
                                universityLogo={university.university_logo}
                                lessonsQuantity="6"
                                courseLink="#"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Universities;