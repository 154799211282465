import React from "react";

const UniversityAbout = ({ about, campus }) => {
  return (
    <div className="content white-bg ">
      <div className="course-overview">
        <div className="inner-box">
          {about ? <p dangerouslySetInnerHTML={{ __html: about }} /> : null}
          
          {campus ? <h4>University Campus</h4> : null}
        
          {campus ?  <p dangerouslySetInnerHTML={{ __html: campus }} /> : null}
          
        </div>
      </div>
    </div>
  );
};

export default UniversityAbout;
