import React from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import FeatureStyle1 from "../Feature/FeatureStyle1";

const SliderDefault = (props) => {
  const config = useSelector((state) => state?.app?.app?.banner);

  const sliderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="rs-slider main-home">
        <Slider {...sliderSettings}>
          {config?.home_banner_image?.map((image, index) => (
            <div key={index}>
              <div
                className="slider-content"
                style={{ backgroundImage: `url(${image.src})` }}
              >
                {/* <img src={image.src} alt={image.src} height={'100%'} width={'100%'} /> */}
                <div className="back-shadow"></div>
              </div>
            </div>
          ))}
        </Slider>
        <FeatureStyle1 config={config} />
      </div>
    </React.Fragment>
  );
};

export default SliderDefault;
