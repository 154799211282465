import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setError, setSuccess } from "../../redux/action/notificationAction";
import { useLocation } from "react-router-dom";
import { GlobalGraphqlcall } from "../../utils/ApiUtil";
import { urlResolver } from "../../graphql/query";
import { setMetaValues } from "../../redux/action/metadataAction";
import { setSource } from "../../redux/action/sourceAction";

const SweetAlertCustom = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const success = useSelector((state) => state.noti?.success);
  const error = useSelector((state) => state.noti?.error);

  useEffect(() => {
    const { pathname } = location;
    if (pathname) {
      fetchUrlResolver(pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const utm_source = params.get("utm_source")
        ? params.get("utm_source")
        : "NA";
      const utm_medium = params.get("utm_medium")
        ? params.get("utm_medium")
        : "NA";
      const utm_campaign = params.get("utm_campaign")
        ? params.get("utm_campaign")
        : "NA";
      const source = {
        utm_source,
        utm_medium,
        utm_campaign,
      };

      dispatch(setSource(source));
    }
  }, [location.search]);

  const fetchUrlResolver = async (url) => {
    var res = await GlobalGraphqlcall(urlResolver, { url: url });

    if (res?.data?.urlResolver) {
      dispatch(setMetaValues(res?.data?.urlResolver));
    } else {
      dispatch(setMetaValues({}));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        dispatch(setSuccess(null));
      }
      if (error) {
        dispatch(setError(null));
      }
    }, 3000);
    if (success) {
      Swal.fire({
        title: success,
        icon: "success",
        timer: 3000,
      });
    }
    if (error) {
      Swal.fire({
        title: error,
        icon: "error",
        timer: 3000,
      });
    }
  }, [success, error]);

  return <></>;
};

export default SweetAlertCustom;
