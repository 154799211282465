export const SET_SUCCESS_TYPE = 'SET_SUCCESS';
export const SET_ERROR_TYPE = "SET_ERROR";

export const setSuccess = (msg) => {
    return {
        type: SET_SUCCESS_TYPE,
        payload: msg
    }
}

export const setError = (msg) => {
    return {
        type: SET_ERROR_TYPE,
        payload: msg
    }
}