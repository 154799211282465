import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const MetaKeys = () => {
    const metaData = useSelector(state => state?.metaData);
   
    if(Object.keys(metaData).length > 0 ) {
        return (
            <Helmet>
                <meta name="title" content={metaData?.metadata_title}></meta>
                <meta name="description" content={metaData?.metadata_description}></meta>
                <meta name="keywords" content={metaData?.metadata_keywords}></meta>
            </Helmet>
        )
    }

    return (<></>);

};

export default MetaKeys;