import { useContext } from "react";
import { LoginModelContext } from "../../app/App";
import Modal from "react-responsive-modal";
import { GoogleLogin } from "@react-oauth/google";
import { GlobalGraphqlcall } from "../../utils/ApiUtil";
import { setLoginUser } from "../../redux/action/authAction";
import { LoginWithGoogle } from "../../graphql/query";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import UpdateProfile from "../UpdateProfile/UpdateProfile";
import { setError, setSuccess } from "../../redux/action/notificationAction";

const LoginModel = () => {
    const { isShow, setShow } = useContext(LoginModelContext);
    const dispatch = useDispatch();
    const user = useSelector((state) => (state.auth?.user));
    
    const responseMessage = (response) => {
        var loginResponse = jwtDecode(response.credential);
        let variables = {
            uuid: loginResponse.aud,
            email: loginResponse.email,
            name: loginResponse.name,
        }
        GlobalGraphqlcall(LoginWithGoogle, variables).then((res) => {
            if (res?.data?.registerLogin) {
                dispatch(setSuccess("Login successfully"));
                dispatch(setLoginUser(res?.data?.registerLogin));
            } else {
                dispatch(setError("Somthing is worng"));
            }
        });
    };

    const errorMessage = (error) => {
        console.log(error);
    };
    const onCloseModal = () => {
        setShow(false);
    }
    return (
        <>
            <Modal open={isShow} onClose={onCloseModal} center >
                <div className="rs-login md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="noticed" style={{ width: "550px" }}>
                            <div className="main-part">
                                <div className="method-account">
                                    <h2 className="login">{user ? <>Profile</>:<>Login</>}</h2>
                                    {user && <p>You are login with <b>{user.user.email}</b></p>}
                                    {!user &&
                                        <div className='google-login'>
                                            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                                        </div>
                                    }
                                    {user &&
                                        <UpdateProfile isModel closeModel={onCloseModal} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default LoginModel;