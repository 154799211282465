import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const filterContext = createContext();
const FilterItem = ({ type, item, nameKey, index, filterSetUrl, localState }) => {
    const filter = useContext(filterContext).filter;
    var typeSlug;
    if (type == 'checkbox') {
        typeSlug = 'check';
    }
    if (type == 'radio') {
        typeSlug = 'radio';
    }
    const [check, setChecked] = useState(false);
    useEffect(() => {
        if (localState && localState.includes(item.value)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [localState]);
    if (item?.parent && nameKey == "state") {
        if (filter.hasOwnProperty('country')) {
            if (!filter?.country.includes(item?.parent)) {
                return <></>;
            }
        } else {
            return <></>;
        }
    }
    return (
        <div className={`${typeSlug}-box form-group`}>
            <input type={type} name={nameKey} id={nameKey + '-' + index} value={item.value} onClick={() => filterSetUrl(item.value)} onChange={() => filterSetUrl(item.value)} checked={check} />
            <label htmlFor={nameKey + '-' + index}>{item.title}</label>
        </div>
    )
}

const FilterSection = ({ options, title, type, value, filterDataSet }) => {
    const [localState, setLocalState] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const location = useLocation();

    const filterSetUrl = (newitem) => {
        if (type == 'checkbox') {
            if (localState && localState?.length > 0) {
                let arr = [...localState];
                if (arr.includes(newitem)) {
                    let index = arr.indexOf(newitem);
                    arr.splice(index, 1);
                } else {
                    arr.push(newitem)
                }
                setLocalState([...arr]);
            } else {
                let arr = [];
                arr.push(newitem)
                setLocalState([...arr]);
            }
        } else if (type == 'radio') {
            let arr = [];
            arr.push(newitem)
            setLocalState([...arr]);
        }
    }

    useEffect(() => {
        if (localState.length >= 0) {
            let obj = {
                [value]: localState
            };
            if(value =="country") {
                obj.state = [];
            }
            // console.log(encodeURIComponent(`${value}=[${localState.toString()}]`),  'localseter');
            filterDataSet(obj);
        }
    }, [localState]);

    useEffect(() => {
        if (firstLoad && value == 'course' && location.state?.filter?.course) {
            setLocalState([
                ...location.state?.filter?.course,
                ...localState,
            ]);
            setFirstLoad(false);
        }
    }, []);

    return (
        <div className="single-filter mb-30">
            <h5 className='mb-1'>{title}</h5>
            {options.map((item, index) => (
                <FilterItem type={type} index={index} key={index} item={item} nameKey={value} filterSetUrl={filterSetUrl} localState={localState} />
            ))}
        </div>
    )
}

const CourseSidebar = ({ fetchCourse, page }) => {

    const filterData = useSelector((state) => (state.filter.course));
    const [filter, setFilter] = useState({});
    const [search, setSearch] = useState('');


    const filterDataSet = (newFilter) => {
        setFilter({
            ...filter,
            ...newFilter
        });
    }

    const filterDataNew = useMemo(() => {
        return { ...filter };
    }, [filter]);

    useEffect(() => {
        fetchCourse(search, filterDataNew);
    }, [filterDataNew, search]);

    useEffect(() => {
        fetchCourse(search, filterDataNew, { page: page });
    }, [page]);



    return (
        <React.Fragment>
            <div className="widget-area">
                <div className="search-widget mb-50">
                    <h3 className="widget-title">Course Search</h3>
                    <div className="search-wrap">
                        <input type="search" placeholder="Searching..." name="s" className="search-input" onChange={(e) => setSearch(e.target.value)} value={search} />
                        <button type="submit" value="Search"><i className=" flaticon-search"></i></button>
                    </div>
                </div>
                <div className="widget-archives mb-50">
                    <h3 className="widget-title">Filter By</h3>
                    <div className="filter-widget">
                        <div className="filter-form">
                            <filterContext.Provider value={{ filter }}>
                                <form method="post" action="#" onSubmit={() => { fetchCourse(search, filter) }}>
                                    {filterData.map((filterOption) => (
                                        <FilterSection {...filterOption} key={filterOption.title} filterDataSet={filterDataSet} />
                                    ))}
                                    {/* <div className="form-group mb-0 mt-3">
                                    <input className="readon2 blue" type="submit" value="Submit Now" />
                                </div> */}
                                </form>
                            </filterContext.Provider>
                        </div>
                    </div>
                </div>
                {/* <div className="widget-archives md-mb-50">
                    <h3 className="widget-title">Course Categories</h3>
                    <ul className="categories">
                        <li><Link to="/course-categories">College</Link></li>
                        <li><Link to="/course-categories">High School</Link></li>
                        <li><Link to="/course-categories">Primary</Link></li>
                        <li><Link to="/course-categories">School</Link></li>
                        <li><Link to="/course-categories">University</Link></li>
                    </ul>
                </div> */}
            </div>
        </React.Fragment>
    );

}

export default CourseSidebar;