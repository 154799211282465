import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GlobalGraphqlcall } from '../../../utils/ApiUtil';
import { LogoutUser } from '../../../graphql/query';
import { setLoginUser } from '../../../redux/action/authAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { LoginModelContext } from '../../../app/App';
import { setSuccess } from '../../../redux/action/notificationAction';

const TopHeader = (props) => {
    const {setShow } = useContext(LoginModelContext);
	const { topBarClass, emailAddress, phoneNumber, Location } = props;
    const user = useSelector((state) => (state.auth?.user?.user));
    const token = useSelector((state) => (state.auth?.user?.token));
    const dispatch = useDispatch();
    const history = useHistory();
    const setLogout = async () => {
        var res = await GlobalGraphqlcall(LogoutUser, {token:token});
        dispatch(setLoginUser());
        dispatch(setSuccess("Logout successfully"));
        history.push('/login');
    }

    return (
        <div className={topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"}>
            <div className="container">
                <div className="row y-middle">
                    <div className="col-md-7">
                        {/* <ul className="topbar-contact">
                            {emailAddress ? 
                                <li>
                                    <i className="flaticon-email"></i>
                                    <a href={'mailto:' + emailAddress}>{emailAddress}</a>
                                </li> : ""
                            }
                            {phoneNumber ? 
                                <li>
                                    <i className="flaticon-call"></i>
                                    <a href={'tel:+' + phoneNumber}>{phoneNumber}</a>
                                </li> : ""
                            }
                            {Location ? 
                                <li>
                                    <i className="flaticon-location"></i>
                                    {Location}
                                </li> : ""
                            }
                        </ul> */}
                    </div>
                    <div className="col-md-5 text-end">
                        <ul className="topbar-right">
                            {user ? <li className="login-register">
                                <i className="fa fa-sign-in"></i>
                                <a role='button' onClick={() => setLogout()} >Logout</a>
                            </li>:<li className="login-register">
                                <i className="fa fa-sign-in"></i>
                                <a role="button" onClick={() => setShow(true)}>Login</a>
                            </li>}
                            
                            <li className="btn-part support-btn">
                                <Link to="/contact" className="apply-btn ">Support</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;