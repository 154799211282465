import { SET_META_VALUES } from "../action/metadataAction";


const initailState = {};
const metaReducer = (state = initailState, action) =>  {
    switch (action.type) {
        case SET_META_VALUES:
            return {
                ...state,
                ...action.payload
            };
    
        default:
            return state;
    }
}
export default metaReducer;