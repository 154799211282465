import React, { useContext, useMemo, useState } from 'react';
import CourseDetailsTab from './CourseDetailsTab';
import CourseSidebar from './CourseSidebar';
import UniversitySidebar from '../../university/university-detail/UniversitySidebar';
import { CourseContext } from '.';

const CourseDetailsPart = () => {
    const course = useContext(CourseContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const buttons = useMemo(() => {
        let newButtons = new Array();
        if (course?.course_intro) {
            newButtons.push("Introduction");
        }
        if (course?.course_fees_expenses) {
            newButtons.push("Fees & Expenses");
        }
        if (course?.course_eligibility_criteria) {
            newButtons.push("Eligibility Criteria");
        }
        if (course?.course_application_process) {
            newButtons.push("Application Process");
        }
        return newButtons;
    }, [course]);

    return (
        <React.Fragment>
            <ul className='d-flex container tab-buttons tab-custom-css'>
                {buttons.map((item, index) => (
                    <li className={`tab-button ${index == activeIndex && 'active'}`} role='button' key={index} onClick={() => setActiveIndex(index)}>{item}</li>
                ))}
            </ul>
            <div className="intro-section pt-94 pb-100 md-pt-80 md-pb-80 loaded">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-8 md-mb-50">
                            <CourseDetailsTab activeIndex={activeIndex} />
                        </div>
                        <div className="video-column col-lg-4">
                            {/* <CourseSidebar /> */}
                            <UniversitySidebar course={course} />
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default CourseDetailsPart;