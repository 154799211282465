import React, { createContext, useEffect, useState } from "react";
import Header from "../../../components/Layout/Header/Header";
import Footer from "../../../components/Layout/Footer/Footer";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import Newsletter from "../../../components/Common/Newsletter";

// Image
import Logo from "../../../assets/img/logo/vision-international.png";
import footerLogo from "../../../assets/img/logo/vision-international.png";
import UniversityDetailsMain from "./UniversityDetailsMain";
import { universityDetailQuery, universityQuery } from "../../../graphql/query";
import { GlobalGraphqlcall } from "../../../utils/ApiUtil";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import UniversityBreadcrumb from "../../../components/University/UniversityBreadcrumb";
import { Helmet } from "react-helmet";

export const UniversityContext = createContext();
export const RelatedUniversityContext = createContext();

const UniversitySingle = () => {
  let { slug } = useParams();
  const [university, setUniversity] = useState(null);
  const [relatedUniversities, setRelatedUniversities] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchApi();
  }, [slug]);

  const fetchApi = async () => {
    try {
      setLoader(true);
      let data = await GlobalGraphqlcall(universityDetailQuery, { slug: slug });
      if (data?.data?.universityDetail) {
        setUniversity(data?.data?.universityDetail);
      }
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const fetchRelatedUniversities = async () => {
    var variable = {
      country: ["Ireland"],
    };
    try {
      let data = await GlobalGraphqlcall(universityQuery, variable);
      if (data?.data?.getUniversityList) {
        setRelatedUniversities(data?.data?.getUniversityList);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <React.Fragment>
      <OffWrap />
      <RelatedUniversityContext.Provider value={relatedUniversities}>
        <UniversityContext.Provider value={university}>
          <Header
            parentMenu="course"
            headerNormalLogo={Logo}
            headerStickyLogo={Logo}
            CanvasLogo={Logo}
            mobileNormalLogo={Logo}
            CanvasClass="right_menu_togle hidden-md"
            headerClass="full-width-header header-style1 home8-style4"
            TopBar="enable"
            TopBarClass="topbar-area home8-topbar"
            emailAddress="support@website.com"
            Location="374 William S Canning Blvd, MA 2721, USA "
          />
          <Helmet>
            <meta name="title" content={university?.metadata_title} />
            <meta
              name="description"
              content={university?.metadata_description}
            />
            <title>{university?.metadata_title}</title>
            <meta name="keywords" content={university?.metadata_keywords} />
          </Helmet>
          {/* breadcrumb-area-start */}
          <UniversityBreadcrumb
            innerClass="white-color university-breadcrumbs-text"
            pageTitle="Learn User Interface and User Experience"
            pageName="Course Details"
            breadcrumbsImg={university?.university_banner}
            universityLogo={university?.university_logo}
            universityName={university?.university_name}
            universityState={university?.state}
            universityCountry={university?.country}
            universityType={university?.university_type}
          />
          {/* breadcrumb-area-start */}

          {/* Course Details Main */}
          <UniversityDetailsMain />
          {/* Course Details Main */}

          <Newsletter
            sectionClass="rs-newsletter style1 gray-bg yellow-color mb--90 sm-mb-0 sm-pb-70"
            titleClass="title mb-0 white-color"
          />

          <Footer
            footerClass="rs-footer home9-style main-home"
            footerLogo={footerLogo}
          />
          <SearchModal />
        </UniversityContext.Provider>
      </RelatedUniversityContext.Provider>
    </React.Fragment>
  );
};

export default UniversitySingle;
