import React, { useEffect } from "react";
import FooterBottom from "./FooterBottom";
import { useSelector } from "react-redux";

const Footer = (props) => {
  const config = useSelector((state) => (state?.app?.app));
  const { footerClass, footerTopClass } = props;

  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className={`footer-top  ${parseInt(config?.newsletter_status, 10) ? '' : 'news-padding'} `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title">Address</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location" style={{"color": "#f5cd19"}}></i>
                  <div className="desc">
                    Matunga West, Mumbai
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
               <h3 className="widget-title">Contact</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <a href="tel:+91 98201 11502">+91 98201 11502</a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <a href="mailto:student@visioninternational.net">student@visioninternational.net</a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title">Connect With Us</h3>
              <ul className="footer_social">
              {config?.fb_link ? <li>
                  <a href={config?.fb_link} target="__blank">
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li> : null}
                {config?.twitter_link ?   <li>
                  <a href={config?.twitter_link} target="__blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li> : null}
                {config?.linkedin_link ? <li>
                  <a href={config?.linkedin_link} target="__blank">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li> : null }
              {config?.youtube_link ? <li>
                  <a href={config?.youtube_link} target="__blank">
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li> : null }
                {config?.insta_link ? <li>
                  <a href={config?.insta_link} target="__blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li> : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
