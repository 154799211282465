import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";

const Cta = () => {
  return (
    <div className="rs-cta main-home ">
      <div className="partition-bg-wrap">
        <div className="container">
          <div className="row">
            <div className="offset-lg-6 col-lg-6 pl-70 md-pl-14">
              <SectionTitle
                sectionClass="sec-title3"
                titleClass="title mb-16"
                title="Why Choose Us?"
                descClass="desc desc-color md-pr-0"
              >
                <ul>
                  <li className="list-icon">
                    Personalized Counselling by Founder & CEO
                  </li>
                  <li className="list-icon">
                    365 Days Support, Online Counselling Available on Sundays
                  </li>
                  <li className="list-icon">
                    300+ Direct University Partnerships
                  </li>
                  <li className="list-icon">
                    6,000+ Vision International students studying in their Dream
                    Universities
                  </li>
                  <li className="list-icon">
                    Centrally Located Office in Matunga West
                  </li>
                  <li className="list-icon">
                    University Delegate Meetings Setup
                  </li>
                  <li className="list-icon">
                    99.6% Visa Acceptance Rate
                  </li>
                  <li className="list-icon">
                    High Touch Student Support Team - available via phone, email
                    & video calls
                  </li>
                  <li className="list-icon">
                    In-house Test Preparation - IELTS, TOEFL, PTE
                  </li>
                  <li className="list-icon">
                    Education Loan Assistance & Student Housing Support
                  </li>
                </ul>
              </SectionTitle>
              {/* <div className="btn-part">
                                <Link className="readon orange-btn transparent" to="/register">Register Now</Link>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
