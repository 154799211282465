import { SET_CONFIG_TYPE } from "../action/appConfigAction";

const initailState = {};
const appReducer = (state = initailState, action) =>  {
    switch (action.type) {
        case SET_CONFIG_TYPE:
            return {
                ...state,
                app: action.payload
            };
    
        default:
            return state;
    }
}
export default appReducer;