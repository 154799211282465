import React from 'react';
import ScrollToTop from '../../../components/Common/ScrollTop';
import UniversityDetailsPart from './UniversityDetailsPart';

const UniversityDetailsMain = () => {
    return (
        <React.Fragment>
            
            {/* CourseDetails Start */}
            <UniversityDetailsPart />
            {/* CourseDetails End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup blue-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default UniversityDetailsMain;