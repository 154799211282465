import React, { useState, useEffect } from "react";

import closeIcon from "../../../assets/img/icon/close.png";
import { GlobalGraphqlcall } from "../../../utils/ApiUtil";
import { globalSearch } from "../../../graphql/query";
import UniversityItem from "./UniversityItem";
import CourseItem from "./CourseItem";

const SearchModal = () => {
  const [search, setSearch] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [loader, setLoader] = useState(false);

  const searchModalRemove = () => {
    document.body.classList.remove("modal-open");
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
    fetchApi(e.target.value);
  };

  const fetchApi = async (value) => {
    try {
      setLoader(true);
      let data = await GlobalGraphqlcall(globalSearch, { search: value });
      if (data?.data?.globalSearch) {
        setSearchData(data?.data?.globalSearch);
      }
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <React.Fragment>
      <div className="search-modal">
        <div onClick={searchModalRemove} className="close">
          <span>
            <img src={closeIcon} alt="Close" />
          </span>
        </div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="search-block clearfix">
              <form>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Search Here..."
                    value={search}
                    onChange={(e) => handleSearch(e)}
                    type="text"
                  />
                </div>
              </form>
            </div>
            {searchData?.universities && searchData?.universities.length > 0 ? (
              <div className="uni-item">
                {searchData?.universities.map((item, index) => (
                  <UniversityItem key={index} item={item} />
                ))}
              </div>
            ) : null}

            {searchData?.courses && searchData?.courses.length > 0 ? (
              <div className="course-item">
                {searchData?.courses.map((item, index) => (
                  <CourseItem key={index} item={item} />
                ))}
              </div>
            ) : null}

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchModal;
