import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import UniversityBreadcrumb from "../../components/University/UniversityBreadcrumb";

// Image
import Logo from "../../assets/img/logo/vision-international.png";
import footerLogo from "../../assets/img/logo/vision-international.png";

import bannerbg from "../../assets/img/breadcrumbs/University-banner-blue.jpg";
import UniversityMain from "./UniversityMain";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UniversitiesList = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("Universities");
  const {country} = useParams()

  function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if(country){
        setPageTitle(`Universities in ${ucFirst(country)}`);
    }
    if (location.state?.filter?.country) {
      setPageTitle(`Universities in ${ucFirst(location.state?.filter?.country[0])}`);
    }
  }, [location.state?.filter?.country, country]);

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle={pageTitle}
        innerClass="breadcrumbs-text"
        pageName="Course"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* University Main */}
      <UniversityMain />
      {/* UniversityMain Main */}

      <Newsletter
        sectionClass="rs-newsletter style1 yellow-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default UniversitiesList;
