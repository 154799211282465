import React, { useState } from "react";
import newsletterIcon from "../../../assets/img/newsletter2.png";
import { useSelector } from "react-redux";
import { GlobalGraphqlcall } from "../../../utils/ApiUtil";
import { newsletter } from "../../../graphql/query";

const Newsletter = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loader, setLoader] = useState(false);

  const config = useSelector((state) => state?.app?.app);
  const { sectionClass, wrapperClass, titleClass, iconPath } = props;
  if (config?.newsletter_status != "1") {
    return null;
  }

  const setNotificationError = (msg) => {
    setNotification({
      error: msg,
    });
    setTimeout(() => {
      setNotification({});
    }, 8000);
  };

  const setSuccess = (msg) => {
    setNotification({
      success: msg,
    });
    setTimeout(() => {
      setNotification({});
    }, 8000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    let errorObj = {};

    if (email == "") {
      errorObj.email = "Email is Required";
      isError = true;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      isError = true;
      errorObj.email = "Email is not valid";
    }

    setError({ ...errorObj });
    try {
      if (!isError) {
        setLoader(true);
        let variables = {
          email,
        };
        let res = await GlobalGraphqlcall(newsletter, variables);
        if (res?.errors) {
          let category = res?.errors[0].extensions?.category;
          let serverError = res?.errors[0].extensions;
          let server_error = serverError[category];
          let errorObj = {};
          for (const obj in server_error) {
            const filedErrorMessage = server_error[obj][0];
            errorObj[obj] = filedErrorMessage;
          }
          setError({ ...errorObj });
          setNotificationError("Somthing is wrong");
        } else {
          setEmail("");
          setSuccess("You have subscribed successfully");
        }
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
      setError({});
      setLoader(false);
    }
  };

  return (
    <div
      className={
        sectionClass
          ? sectionClass
          : "rs-newsletter style1 blue-color mb--90 sm-mb-0 sm-pb-70"
      }
    >
      <div className="container pt-100">
        <div className={wrapperClass ? wrapperClass : "newsletter-wrap"}>
          <div className="row y-middle">
            <div className="col-lg-6 col-md-12 md-mb-30">
              <div className="content-part">
                <div className="sec-title">
                  <div className="title-icon md-mb-14">
                    <img
                      src={iconPath ? iconPath : newsletterIcon}
                      alt="icon"
                    />
                  </div>
                  <h2
                    className={
                      titleClass ? titleClass : "title mb-0 white-color"
                    }
                  >
                    Subscribe to Newsletter
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="newsletter-form">
                <div>
                  <form
                    method="post"
                    action="#"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <input
                      className="from-control"
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="E-Mail"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {error?.email && (
                      <span className="text-danger">{error?.email}</span>
                    )}
                    {notification?.error && (
                      <span className="text-danger">{notification?.error}</span>
                    )}
                    {notification?.success && (
                      <span className="text-white">
                        {notification?.success}
                      </span>
                    )}
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
