import { SET_ERROR_TYPE, SET_SUCCESS_TYPE } from "../action/notificationAction";


const initailState = {
    success: null,
    error: null
};
const notificationReducer = (state = initailState, action) => {
    switch (action.type) {
        case SET_SUCCESS_TYPE:
            return {
                ...state,
                success: action.payload
            };

        case SET_ERROR_TYPE:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}
export default notificationReducer;