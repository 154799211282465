import React, { useContext, useEffect, useState } from "react";
import { GlobalGraphqlcall } from "../../../utils/ApiUtil";
import { universityDetailQuery } from "../../../graphql/query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LightboxCustom from "../../../components/LightboxCustom";
import { UniversityContext } from ".";

const UniversityGallery = () => {
  const university = useContext(UniversityContext);
  const [universities, setUniversities] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    try {
      if (university) {
        var items = [];
        university?.university_images.map((row) => {
          items.push({
            original: row.src,
            src: row.src,
            width: 320,
            height: 174,
          });
        });

        setUniversities(items);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <div className="content pb-30 white-bg">
      {/* <ImageGallery items={universities} /> */}

      <LightboxCustom images={universities}/>
    </div>
  );
};

export default UniversityGallery;
