import { SET_SOURCE_TYPE } from "../action/sourceAction";

const initailState = {
    source: {
        utm_source : "NA",
        utm_medium : "NA",
        utm_campaign : "NA",
    }
};
const sourceReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_SOURCE_TYPE:
      return {
        ...state,
        source: { ...action.payload },
      };

    default:
      return state;
  }
};
export default sourceReducer;
