import React, { useEffect } from "react";

const CourseIntro = ({ intro, course_name }) => {
  return (
    <div className="content white-bg ">
      <div className="course-overview">
        <div className="inner-box">
          {/* <p dangerouslySetInnerHTML={{ __html: about }} /> */}
          {intro ? <h3>Course Introduction</h3> : null }
          {intro ? <p dangerouslySetInnerHTML={{ __html: intro }} /> : null }
        </div>
      </div>
    </div>
  );
};

export default CourseIntro;
