export const LoginWithGoogle = `mutation registerLogin ($uuid: String!, $email: String!, $name: String!)  {
    registerLogin (uuid:$uuid, email:$email, name:$name)
 {
    user {
        email
        name
        mobile
        image
    }
    token
    
 }
}`;

export const LogoutUser = `mutation logoutUser($token: String!) {
    logoutUser (token:$token) 
     {
        message
    }
}`;

export const getUser = `query GetUser($token: String!) {
    getUser(token: $token) {
        email
        name
        mobile
        image
    }
}`;

export const universityQuery = `query GetUniversityList($name: String, $course: [String], $state:[String], $country: [String], $type: [String], $course_type: [String], $price: [String], , $page: Int, $perpage: Int) {
    getUniversityList(university_name:$name, course: $course, state: $state, country: $country, type: $type, course_type: $course_type, price: $price, page: $page, perpage: $perpage){
        data {
            id
            university_slug
            university_name
            email
            mobile
            state
            country
            about_university
            university_type
            university_logo
            university_banner
            course_count
        }
        total
        parpage
    }
}`;

export const universityDetailQuery = `query universityDetail($id: Int, $slug: String) {
    universityDetail(id:$id,slug:$slug){
        id
        university_name
        university_slug
        email
        mobile
        state
        country
        country_slug
        about_university
        university_type
        university_campus
        admission_eligibility
        admission_application_deadlines
        ranking_details
        university_banner
        university_logo
        metadata_title
        metadata_description
        metadata_keywords
        state_v2{
            state_weather_image{
               src
           }
        }
        university_images{
            src
        }
        related_universities {
            university_slug
            university_name
            email
            mobile
            state
            country
            about_university
            university_type
            university_logo
        }
        courses {
            id
            course_name
            course_slug
            course_intro
            course_ranking
            course_fees_expenses
            course_eligibility_criteria
            course_application_process
            course_price
        }
    }
}`;

export const AllCoursesQuery = `query getCourseList($slug: String, $course: [String], $state:[String], $country: [String], $type: [String], $course_type: [String], $price: [String], $universities: [String], $page: Int, $perpage: Int){
    getCourseList(slug:$slug, course: $course, state: $state, country: $country, type: $type, course_type: $course_type, price: $price, universities: $universities, page: $page, perpage: $perpage){
        data {
            id
            course_name
            course_slug
            course_intro
            course_fees_expenses
            course_eligibility_criteria
            course_application_process
            course_price
            university{
                university_name
                university_type
                university_logo
                university_slug
                country
                country_slug
                state
                related_universities {
                    university_slug
                    university_name
                    email
                    mobile
                    state
                    country
                    about_university
                    university_type
                    university_logo
                }
            }
        }
        total
        parpage
    }
}`;

export const getCourse = `query getCourse($id: Int, $course_slug: String, $university_slug: String){
    getCourse(id: $id, course_slug: $course_slug, university_slug: $university_slug){
        id
        course_name
        course_slug
        course_ranking
        course_intro
        course_fees_expenses
        course_eligibility_criteria
        course_application_process
        course_price
        metadata_title
        metadata_description
        metadata_keywords
        brochure_pdf {
            name
            url
            download_url
        }
        university{
            id
            university_name
            university_type
            university_logo
            university_slug
            university_banner
            state
            university_type
            country
            country_slug
            related_universities {
                university_slug
                university_name
                email
                mobile
                state
                country
                about_university
                university_type
                university_logo
            }
        }
    }
}`;

export const homeConfigurations = `query homeConfiguration {
    homeConfiguration{
        fb_link
        insta_link
        youtube_link
        linkedin_link
        twitter_link
        logo_alt_text
        copyright_text
        brand_logo
        newsletter_status
        banner{
            banners_heading
            banners_sub_heading
            banners_cta_text
            banners_cta_link
            banner_status
            home_banner_image{
                src
            }
        }
        specializations{
            id
            course_type_id
            specialization_name
            specialization_icon
        }
        sidebar{
            sidebar_button_title
            promotional_text
            sidebar_cta_image
            sidebar_promotional_banner_image {
                src
                source_type
            }
        }
        home_page_metadata{
            metadata_title
            metadata_description
            metadata_keywords
        }
        home_page_university{
            university_slug
            university_name
            university_logo
            university_banner
            state
            country
        }
        home_page_specialization{
            id
            course_type_id
            specialization_name
            specialization_icon
            course_count
        }
    }
}`;

export const universityFilter = `query getUniversitiesFilter {
    getUniversitiesFilter {
        title
        value
        type
        options {
            title
            value
            parent
        }
    }
}`;

export const courseFilter = `query getCourseFilter {
    getCourseFilter {
        title
        value
        type
        options {
            title
            value
            parent
        }
    }
}`;

export const contactUsers = `mutation contactUs($name: String!, $email: String!, $mobile: String, $message: String, $utm_source: String,$utm_medium: String, $utm_campaign: String, $page_name: String)
{
    contactUs(name:$name, email:$email, mobile:$mobile, message:$message, utm_source:$utm_source, utm_medium:$utm_medium, utm_campaign:$utm_campaign, page_name:$page_name)
    {
        name
        email
        mobile
        message 
        utm_source
        utm_medium
        utm_campaign
        page_name
    }
}`;

export const leadsquaredApiApplyToUniversity = `mutation  applyToUniversity ($university_id: Int, $name: String!, $university_name: String,  $email: String!, $mobile: String!, $location: String!, $course: String, $utm_source: String,$utm_medium: String, $utm_campaign: String, $page_name: String) {
    applyToUniversity(university_id:$university_id, university_name: $university_name, name:$name, email:$email, mobile:$mobile, location:$location, course: $course, utm_source:$utm_source, utm_medium:$utm_medium, utm_campaign:$utm_campaign, page_name:$page_name)
    {
        university_id
        name
        email
        mobile
        location
        course
        utm_source
        utm_medium
        utm_campaign
        page_name
    }
}`;

export const globalSearch = `query GlobalSearch($search: String) {
    globalSearch(search: $search) {
        universities {
            university_name
            university_slug
            university_type
            university_logo
            email
            state
        }
        courses {
            id
            course_slug
            course_name
            university {
                university_logo
                university_name
                university_slug
                university_type
            }
        }
    }
}`;

export const newsletter = `mutation newsletter($email: String!){
    newsletter(email:$email)
    {
        email
        message
    }
}`;

export const urlResolver = `mutation urlResolver($url: String) {
    urlResolver(url: $url) {
        metadata_title
        meta_url
        metadata_description
        metadata_keywords
    }
}`;