import React, { createContext, useEffect, useMemo, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//Custom Components
import Home from "../pages/home";
import Faq from "../pages/faq";
import Login from "../pages/login";
import Register from "../pages/register";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import UniversitySingle from "../pages/university/university-detail";
import UniversitiesList from "../pages/university";
import MyAccount from "../pages/account/my-account";
import Contact from "../pages/contact";
import CourseIndex from "../pages/coures";
import CourseDetailPage from "../pages/coures/courses-detail";
import { GlobalGraphqlcall } from "../utils/ApiUtil";
import {
  courseFilter,
  homeConfigurations,
  universityFilter,
} from "../graphql/query";
import { useDispatch } from "react-redux";
import { setConfigApp } from "../redux/action/appConfigAction";
import {
  setCourseFilter,
  setUniversityFilter,
} from "../redux/action/filterAction";
import LoginModel from "../components/LoginModel";
// import Notification from "../components/Notification/Notification";
import { setMobileDevice } from "../redux/action/deviceAction";
import SweetAlertCustom from "../components/Notification/SweetAlertCustom";
import MetaKeys from "../components/MetaKeys/MetaKeys";

export const API_HOST = process.env.REACT_APP_API_HOST;

export const LoginModelContext = createContext({
  isShow: false,
});
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const App = (props) => {
  const dispatch = useDispatch();
  const [isLoginModelShow, setIsLoginModelShow] = useState(false);

  useEffect(() => {
    fetchApi();
    fetchUniversityFilterApi();
    fetchCourseFilterApi();
  }, []);

 

  const setWindowSize = ({ innerWidth }) => {
    if (innerWidth < 768) {
      dispatch(setMobileDevice(true));
    } else {
      dispatch(setMobileDevice(false));
    }
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const fetchCourseFilterApi = async () => {
    try {
      var res = await GlobalGraphqlcall(courseFilter);
      dispatch(setCourseFilter(res?.data?.getCourseFilter));
    } catch (error) {
      dispatch(setCourseFilter([]));
    }
  };

  const fetchUniversityFilterApi = async () => {
    try {
      var res = await GlobalGraphqlcall(universityFilter);
      dispatch(setUniversityFilter(res?.data?.getUniversitiesFilter));
    } catch (error) {
      dispatch(setUniversityFilter([]));
    }
  };

  const fetchApi = async () => {
    try {
      var res = await GlobalGraphqlcall(homeConfigurations);
      dispatch(setConfigApp(res?.data?.homeConfiguration));
    } catch (error) {
      dispatch(setConfigApp({}));
    }
  };

  const loginModelState = useMemo(
    () => ({
      isShow: isLoginModelShow,
      setShow: setIsLoginModelShow,
    }),
    [isLoginModelShow]
  );

  return (
    <div className="App">
      <LoginModelContext.Provider value={loginModelState}>
        <Router>
          <LoadTop />
          <MetaKeys />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/universities" exact component={UniversitiesList} />
            <Route
              path="/universities/:country"
              exact
              component={UniversitiesList}
            />
            <Route
              path="/universities/:country/:state"
              exact
              component={UniversitiesList}
            />
            <Route path="/home" component={Home} />
            <Route path="/university/:slug" component={UniversitySingle} />
            <Route path="/courses" component={CourseIndex} />
            <Route path="/courses/:slug" component={CourseIndex} />
            <Route
              path="/course/:slug/:course"
              exact
              component={CourseDetailPage}
            />
            <Route path="/faq" component={Faq} />
            <Route path="/login" component={Login} />
            <Route path="/account/my-account" component={MyAccount} />
            <Route path="/register" component={Register} />
            <Route path="/contact" component={Contact} />
            <Route path={"*"} component={Error} />
          </Switch>
          <SweetAlertCustom />
        </Router>
        <LoginModel />
        
      </LoginModelContext.Provider>
    </div>
  );
};

export default App;
