import React from "react";
import { Link } from "react-router-dom";

const CourseCard = (props) => {
  const { courseClass, course, university, btnText } = props;
  const { id, course_name, course_slug } = course;
  const { university_slug, university_name, university_type, university_logo, state, country } =
    university;

  const location = {
    pathname: `/course/${university_slug}/${course_slug}`,
    state: {
      course_id: id,
    },
  };
  return (
    <div className={courseClass ? courseClass : "courses-item"}>
      <div className="img-part uni-logo">
        <img src={university_logo} height={80} width={100} />
      </div>
      <div className="content-part">
        <div className="sub-content">
          <h3 className="title course-title">
            <Link to={location}>
              <div dangerouslySetInnerHTML={{ __html: course_name }}></div>
            </Link>
          </h3>
        </div>
        <div className="row">
          <div className="col-md-auto">
            <span>Location</span>
            <br />
            <p className="categorie">{state ? state : "Gujarat"},{" "}
            {country ? country : "India"}</p>
          </div>
          <div className="col-md-auto">
            <span>University Name</span>
            <br />
           <p> <Link to={location} className="uni-txt">
              {university_name ? university_name : "Web Development"}
            </Link></p>
          </div>
          <div className="col-md-auto course-category">
            <span>University Type</span>
            <br />
            <Link to={location} className="categorie">
              {university_type
                ? university_type + " University"
                : " - "}
            </Link>
          </div>
        </div>
        
        <div className="bottom-part">
          <div className="btn-part course-buttons">
            <Link to={location}>
              {btnText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
